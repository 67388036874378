import * as React from 'react';

import {
	Box,
	HStack,
	Image,
	Link,
	Skeleton,
	Text,
	VStack,
} from '@chakra-ui/react';

import { FunctionComponent } from 'react';

type BlogPostIntro = {
	link: string;
	blogImgThumb?: string;
	category?: string;
	postTitle: string;
	excerpt: string;
	author?: string;
	authorSlug?: any;
	date?: string;
	uri?: string;
	arrowImage?: string;
	heroBackgroundPadding?: string;
};
const BlogHeroIntro: FunctionComponent<BlogPostIntro> = ({
	link,
	blogImgThumb,
	category,
	postTitle,
	excerpt,
	author,
	authorSlug,
	date,
}) => {
	const displayTruncatedExcerpt =
		excerpt?.slice(0, 150) + (excerpt?.length > 150 ? '...' : '');
	return (
		<Box
			// h='660px'
			borderRadius='20px'
			boxShadow='0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);'>
			<Link
				href={link}
				_hover={{ textDecoration: 'none', color: '#595959!important' }}>
				<Box
					h='279px'
					w='100%'
					borderTopLeftRadius='20px'
					borderTopRightRadius='20px'
					backgroundImage={
						blogImgThumb
							? blogImgThumb
							: 'https://shop.doctormedica.co/wp-content/uploads/2024/01/bbbbb7-65b281b4ec5b7.webp'
					}
					bgPosition='center'
					bgRepeat='no-repeat'
					bgSize='cover'
				/>
			</Link>
			<VStack
				alignItems='flex-start'
				p='24px'>
				<HStack
					w='100%'
					justifyContent='space-between'>
					<Text
						as='span'
						color='#120B0C'
						fontSize='12px'
						fontWeight='400'>
						{date}
					</Text>
					<Link href={`/blog/author/${authorSlug}`}>
						<Text
							fontSize='14px'
							color='#D28C49'>
							{author}
						</Text>
					</Link>
				</HStack>
				<Link
					href={link}
					_hover={{ textDecoration: 'none', color: '#595959!important' }}>
					<Text
						as='h3'
						color='#262626'
						fontSize='20px'
						fontWeight={700}
						_hover={{ color: '#D28C49' }}
						transition='0.3s ease'
						dangerouslySetInnerHTML={{ __html: postTitle }}
					/>
				</Link>
				<Box>
					<Text
						color='#595959'
						fontSize='16px'
						fontWeight='400'
						lineHeight='150%'
						dangerouslySetInnerHTML={{ __html: displayTruncatedExcerpt }}
					/>
				</Box>
				<Box
					bg='#FEF1E5'
					p='4px 12px'
					borderRadius='99px'>
					<Text
						as='span'
						color='#B97230'>
						{category?.slice(0, 1)}{' '}
					</Text>
				</Box>
			</VStack>
		</Box>
	);
};

export default BlogHeroIntro;
