import * as React from 'react';

import {
	Box,
	Button,
	HStack,
	Heading,
	Image,
	Link,
	Stack,
	Text,
	VStack,
	useMediaQuery,
} from '@chakra-ui/react';

import BlogHeroPost from '../components/blog-hero-post';
import BookAMeetingBlogSection from '../components/BlogBanners/BookAMeeting';
import Layout from '../components/Base/Layout';
import LogInBanner from '../components/BlogBanners/LogInBanner';
import ReactDOM from 'react-dom';
import RelatedPost from '../components/RelatedPost';
import { SEO } from '../components/Base/Seo';
import { SingleProductCard } from '../components/Product/SingleProductCard';
import { fetchAllProducts } from '../queries/fetchAllProducts';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import useAuth from '../components/useAuth';
import { useEffect } from 'react';

const Div = styled.div`
	.ez-toc-icon-toggle-span {
		display: none;
	}
	.ez-toc-list-level-1,
	.ez-toc-list-level-2 {
		padding: 4px 0;
	}
	.ez-toc-list-level-3 {
		margin-left: 1rem;
		padding: 4px 0;
	}
	.ez-toc-title-container {
		display: flex;
		align-items: center;
		gap: 20px;
	}
	.ez-toc-title-toggle,
	.ez-toc-list.ez-toc-list-level-1 {
		margin-bottom: 1rem;
	}

	.wp-block-heading,
	.wp-block-image {
		padding-top: 16px;
		padding-bottom: 16px;
	}
	.postArticle > p {
		padding: 16px 0;
	}
`;

const BlogPostTemplate = ({ data }: any) => {
	const date = data.wp.post.date.slice(0, 10);

	const content = data.wp.post.content;
	const modified = data.wp.post.modified.slice(0, 10);
	const mentionedProducts = data.wp.post.mentionedProducts.mentionedProducts;
	const products = fetchAllProducts();
	const { loggedIn, isUnlicensedUser, isCustomerDefault } = useAuth();

	const pharmas =
		mentionedProducts && mentionedProducts.length > 0
			? mentionedProducts.some((product: any) => product.ispharmaceutical)
			: false;

	const firstH2Index = content.indexOf('<h2');
	const beforeFirstH2 = content.substring(0, firstH2Index);
	const secondH2Index = content.indexOf('<h2', firstH2Index + 1);
	const afterFirstH2 = content.substring(
		firstH2Index,
		secondH2Index !== -1 ? secondH2Index : undefined
	);
	const afterSecondH2 =
		secondH2Index !== -1 ? content.substring(secondH2Index) : '';

	const [isLargerScreen] = useMediaQuery('(max-width: 768px)');

	useEffect(() => {
		const renderCustomComponent = () => {
			const faqElement = document.getElementById('h-faq');
			const referencesElement = document.getElementById('h-references');
			const faqsElement = document.getElementById('h-faqs');
			const referenceElement = document.getElementById('h-reference');

			const elements = [
				faqElement,
				referencesElement,
				faqsElement,
				referenceElement,
			];
			const foundElement = elements.find((element) => element !== null);

			if (foundElement && foundElement.parentNode) {
				const container = document.createElement('div');

				ReactDOM.render(<BookAMeetingBlogSection />, container);

				foundElement.parentNode.insertBefore(container, foundElement);
			} else {
				const container = document.createElement('div');
				const targetDiv = document.querySelector('.css-1345fpq');

				if (targetDiv) {
					targetDiv.appendChild(container);

					ReactDOM.render(<BookAMeetingBlogSection />, container);
				}
			}
		};

		renderCustomComponent();
	}, []);

	return (
		<>
			<Layout>
				<Div>
					<BlogHeroPost
						link={'/blog' + data.wp.post.uri}
						blogImgThumb={data.wp.post.featuredImage?.node?.sourceUrl}
						category={data.wp.post.categories.nodes.map(
							(item: any, index: number, array: string | any[]) => {
								const lastElement = array.length - 1;
								if (lastElement == index) {
									return <Text key={item.name}>{item.name}</Text>;
								}
								return (
									<Text
										key={item.name}
										marginRight='2'>
										{item.name},{' '}
									</Text>
								);
							}
						)}
						postTitle={data.wp.post.title}
						excerpt={data.wp.post.seo.metaDesc}
						author={data.wp.post.author.node.name}
						date={date}
						modified={modified}
					/>
					<HStack
						id='anchorlink'
						flexDirection={{ base: 'column', xl: 'row' }}
						alignItems='flex-start'
						maxW='1200px'
						margin='0px auto'
						py='48px'
						gap='20px'
						px={{ base: '20px', xl: '0px' }}>
						<Box maxW={{ base: '100%', xl: '800px' }}>
							<Stack
								alignItems='center'
								pb='24px'>
								<Link href='/promotions'>
									<Image
										w={isLargerScreen ? 'auto' : '600px'}
										alt='Limited Time Deals Promo Banner'
										src={
											isLargerScreen
												? '/mobile-blog-banner.webp'
												: '/blog-promo-banner.webp'
										}
										borderRadius={'8px'}
									/>
								</Link>
							</Stack>
							<p>
								<Text dangerouslySetInnerHTML={{ __html: beforeFirstH2 }} />

								{!loggedIn && <LogInBanner />}

								<Text
									className={'postArticle'}
									mt={{ base: 6, md: 4 }}
									mb={{ base: 8, md: 0 }}
									dangerouslySetInnerHTML={{ __html: afterFirstH2 }}
								/>

								{!loggedIn && pharmas ? (
									<VStack
										justifyContent='center'
										w={{ base: '303px', md: '640px' }}
										h='200px'
										mx='auto'
										backgroundImage={{
											base: '/dm-banner-mob.webp',
											md: '/dm-banner-back.webp',
										}}
										backgroundSize='contain'
										bgPos={'center'}
										bgRepeat='no-repeat'
										color='#232323'>
										<Box
											w={{ base: '220px', md: '315px' }}
											textAlign='center'>
											<Text
												alignSelf='auto!important'
												fontSize='md'
												fontWeight={400}
												lineHeight='150%'>
												Interested in purchasing this product?
											</Text>
											<Heading
												mb={4}
												fontSize='2xl!important'
												fontWeight={700}
												lineHeight='133%'>
												We can help.
											</Heading>
											<Link
												href='https://talktodoctormedica.youcanbook.me/'
												target='_blank'
												w={{ base: '220px', md: '100%' }}>
												<Button
													fontSize='sm'
													w={{ base: '220px', md: '100%' }}
													fontWeight={700}
													color='#120B0C'
													variant='outline'
													textTransform={'uppercase'}>
													Book a Consultation
												</Button>
											</Link>
										</Box>
									</VStack>
								) : loggedIn &&
								  pharmas &&
								  (isUnlicensedUser || isCustomerDefault) ? (
									<VStack
										justifyContent='center'
										w={{ base: '303px', md: '640px' }}
										h='200px'
										mx='auto'
										backgroundImage={{
											base: '/dm-banner-mob.webp',
											md: '/dm-banner-back.webp',
										}}
										backgroundSize='contain'
										bgPos={'center'}
										bgRepeat='no-repeat'
										color='#232323'>
										<Box
											w={{ base: '220px', md: '355px' }}
											textAlign='center'>
											<Text
												alignSelf='auto!important'
												fontSize='md'
												fontWeight={400}
												lineHeight='150%'>
												Don't miss out on our full range of products.
											</Text>
											<Heading
												mb={4}
												fontSize='2xl!important'
												fontWeight={700}
												lineHeight='133%'>
												Upgrade your license to view it.
											</Heading>
											<Link
												href='https://talktodoctormedica.youcanbook.me/'
												target='_blank'
												w={{ base: '220px', md: '100%' }}>
												<Button
													fontSize='sm'
													w={{ base: '220px', md: '236px' }}
													fontWeight={700}
													color='#120B0C'
													variant='outline'
													textTransform={'uppercase'}>
													Upgrade Your Licence
												</Button>
											</Link>
										</Box>
									</VStack>
								) : (
									<Box
										display='flex'
										flexFlow={{ base: 'column', md: 'row' }}
										alignItems='center'
										justifyContent='center'>
										{products?.map((item: any) => {
											const mentionedProduct = mentionedProducts?.find(
												(mp: any) => mp.productId == item.databaseId
											);

											if (mentionedProduct) {
												return (
													<SingleProductCard
														key={item.databaseId}
														props={item}
														qtyInputOn={false}
														outlet={false}
														margin={'.3rem'}
														width={'236px'}
													/>
												);
											}
										})}
									</Box>
								)}

								<Text
									className={'postArticle'}
									mt={{ base: 6, md: 4 }}
									mb={{ base: 8, md: 0 }}
									dangerouslySetInnerHTML={{ __html: afterSecondH2 }}
								/>
							</p>
						</Box>
						<VStack
							w={{ base: '100%', xl: '400px' }}
							gap='16px'
							pt='40px'>
							<Text
								fontSize='20px'
								fontWeight='700'>
								Related Articles
							</Text>
							<RelatedPost />
						</VStack>
					</HStack>
				</Div>
			</Layout>
		</>
	);
};

export const Head = ({ data }: any) => (
	<SEO
		title={data.wp.post.seo.title}
		description={data.wp.post.seo.metaDesc}
		featuredImage={data.wp.post.featuredImage?.node?.sourceUrl}
		content='article'
	/>
);

//GRAPHQL
export const pageQuery = graphql`
	query PostById($id: ID!) {
		wp {
			post(id: $id) {
				mentionedProducts {
					mentionedProducts {
						productId
						ispharmaceutical
					}
				}
				id
				date
				modified
				modifiedGmt
				title
				excerpt
				uri
				slug
				seo {
					title
					metaDesc
				}
				content(format: RENDERED)
				featuredImage {
					node {
						sourceUrl
					}
				}
				categories {
					nodes {
						id
						name
						taxonomyName
					}
				}
				author {
					node {
						name
						slug
					}
				}
			}
		}
	}
`;

export default BlogPostTemplate;
