import {
	Box,
	Button,
	HStack,
	Image,
	Link,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';

import React from 'react';

const BookAMeetingBlogSection = () => {
	return (
		<Box
			my={12}
			px='40px'
			w='100%'
			h={{ base: '100%', md: '224px' }}
			bgImage='https://shop.doctormedica.co/wp-content/uploads/2024/05/backgroundimage-6659bd37297cf.webp'
			backgroundRepeat='no-repeat'
			backgroundSize='cover'
			backgroundPosition='center'>
			<HStack
				flexDirection={{ base: 'column', md: 'row' }}
				h='100%'
				justifyContent='space-between'>
				<VStack alignItems='flex-start'>
					<Box
						h='2px'
						w='24px'
						bg='#120B0C'></Box>
					<Text
						fontSize='24px'
						lineHeight='36px'
						fontWeight='400'>
						Need <span style={{ color: '#D28C49' }}> shopping assistance</span>
						<br></br>
						or <span style={{ color: '#D28C49' }}> more information</span>?
					</Text>
					<Link href='https://talktodoctormedica.youcanbook.me/'>
						<Button
							variant='outline'
							h='45px'
							padding='12px 20px'
							fontWeight='700'
							border='0.4px solid #120B0C'
							borderRadius='48px'
							fontSize='12px'
							_hover={{ color: '#fff', bg: '#120B0C' }}>
							BOOK A CONSULTATION
						</Button>
					</Link>
				</VStack>
				<Image
					w='280px'
					h='224px'
					alt='Women Tech Support'
					src='https://shop.doctormedica.co/wp-content/uploads/2024/05/support_girl.webp'
				/>
			</HStack>
		</Box>
	);
};

export default BookAMeetingBlogSection;
