import {
	Box,
	HStack,
	Image,
	Link,
	SimpleGrid,
	Skeleton,
	Text,
	TextDecorationProps,
	VStack,
} from '@chakra-ui/react';
import { gql, useQuery } from '@apollo/client';
import { graphql, useStaticQuery } from 'gatsby';

import BlogHeroIntro from './blog-hero-intro';
import React from 'react';

const RelatedPost = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpPost {
				nodes {
					seo {
						metaDesc
					}
					featuredImage {
						node {
							sourceUrl
						}
					}
					author {
						node {
							name
							slug
						}
					}
					id
					date(formatString: "YYYY-MM-DD")
					modified
					modifiedGmt
					title
					excerpt
					uri
					slug
					content
					categories {
						nodes {
							name
							description
						}
					}
				}
			}
		}
	`);

	function shuffleArray(array: any) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	}

	const shuffledPosts = shuffleArray(data.allWpPost.nodes);

	return (
		<>
			<Box w='100%'>
				<SimpleGrid
					columns={{ base: 1, md: 2, lg: 3, xl: 1 }}
					gap={{ base: '12px', lg: '12px' }}>
					{shuffledPosts.slice(0, 3).map((item: any, index: number) => {
						return (
							<BlogHeroIntro
								key={index} // Don't forget to add a unique key
								link={'/blog/' + item.slug}
								blogImgThumb={item.featuredImage?.node?.sourceUrl}
								category={item.categories.nodes.map((item: any) => item.name)}
								postTitle={item.title}
								excerpt={item.seo.metaDesc}
								author={item.author?.node?.name}
								authorSlug={item.author?.node?.slug}
								date={item.date}
							/>
						);
					})}
				</SimpleGrid>
			</Box>
		</>
	);
};

export default RelatedPost;
